import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"

const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 15" />
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 15</h1>
      <p>Er jeg våken, eller sover jeg nå?</p>
      <p>Wow, i natt var det heftig våketid. Det var vanskelig å sovne - lurer på om det er fordi jeg er så spent i disse adventstider. Jaja, jeg vokser det nok av meg.</p>
      <p>Håper tyggisen smaker godt. Pappa elsker deg! Og jeg elsker den nye vannflaska!</p>
      <p>Hilsen Litjen</p>
    </div>
  </Layout>
)

export default LitjenLuke
